//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { generateRandomString } from '@/utils/helpers/string-helper'
import windowResizeMixin from '@/mixins/window-resize-mixin'
import { modalAnimations } from '~/utils/helpers/modal-helper'

export default {
  mixins: [
    windowResizeMixin
  ],

  props: {
    modalId: {
      type: String,
      default: 'modalDefault'
    },
    animation: {
      type: String,
      default: modalAnimations.fade
    },
    ariaLabelledby: {
      type: String,
      default: () => null
    },
    hasCloseButton: {
      type: Boolean,
      default: true
    },

    modalDialogWidth: {
      type: String,
      default: '450px'
    },
    verticalCentered: {
      type: Boolean,
      default: true
    },

    customClassModalBody: {
      type: String,
      default: () => null
    },
    customClassModalFooter: {
      type: String,
      default: () => null
    }
  },

  data () {
    return {
      modalElement: null,
      modalDialogRefName: generateRandomString(6),

      isModalDialogResponsive: false,
      initialModalDialogWidth: null
    }
  },

  computed: {
    modalAnimations () {
      return modalAnimations
    },

    hasHeaderSlot () {
      return !!this.$slots.header
    },

    hasBodySlot () {
      return !!this.$slots.body
    },

    hasFooterSlot () {
      return !!this.$slots.footer
    }
  },

  watch: {
    windowWidth: {
      handler () {
        this.setIsModalDialogResponsive()
      },
      deep: true
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.modalElement = document.getElementById(this.modalId)
      if (!this.modalElement) { return }

      this.modalElement.addEventListener('shown.bs.modal', () => {
        this.initialModalDialogWidth = this.$refs[this.modalDialogRefName].offsetWidth
        this.setIsModalDialogResponsive()

        this.$emit('modal-shown')
      })

      this.modalElement.addEventListener('hidden.bs.modal', () => {
        this.$emit('modal-closed')
      })
    })
  },

  beforeDestroy () {
    if (!this.modalElement) { return }

    this.modalElement.removeEventListener('shown.bs.modal', () => {
      //
    })
    this.modalElement.removeEventListener('hidden.bs.modal', () => {
      //
    })
  },

  methods: {
    generateRandomString,

    setIsModalDialogResponsive () {
      if (process.client) {
        const modalDialog = this.$refs[this.modalDialogRefName]
        if (!modalDialog) { return false }

        const modalDialogWidth = modalDialog.offsetWidth
        if (modalDialogWidth !== 0) {
          if (this.isModalDialogResponsive) {
            this.isModalDialogResponsive = this.windowWidth < this.initialModalDialogWidth + 60
            return
          }

          this.isModalDialogResponsive = this.windowWidth < modalDialogWidth + 60
        }
      }
    },

    // Accessible from parent component
    closeModal () {
      this.$refs.buttonClose.click()
    }
  }
}
