import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=7df6ec0a&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=7df6ec0a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7df6ec0a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconInstagram: require('/usr/src/app/components/icons/IconInstagram.vue').default,IconTiktok: require('/usr/src/app/components/icons/IconTiktok.vue').default,IconYoutube: require('/usr/src/app/components/icons/IconYoutube.vue').default,IconEmail: require('/usr/src/app/components/icons/IconEmail.vue').default,IconSocialWhatsapp: require('/usr/src/app/components/icons/IconSocialWhatsapp.vue').default,CopyrightFooter: require('/usr/src/app/components/home/base/CopyrightFooter.vue').default})
